import httpUtil from "@/utils/httpUtil";

/***获取当前企业策略设置 */
export const getMySetting = params => httpUtil.get("/crk/setting", params);

/***
 * 保存策略配置
 * @param params
 * @returns {Promise<R>}
 */
export const saveSetting = params => httpUtil.post("/crk/setting", params);


/***
 * 获取所有策略
 * @param params
 * @returns {Promise<R>}
 */
export const getAllStrategy = params => httpUtil.get("/crk/strategy", params);

export const getStrategyInfo = params => httpUtil.get("/crk/strategy/getStrategyInfo", params);
export const getStrategyWarehouse = params => httpUtil.get("/crk/produce/getStrategyWarehouse", params);

export const getOutStrategyInfo = params => httpUtil.get("/crk/order/getStrategyInfo", params);
