<template>
  <div class="view pa24">

    <div>
      <div style="margin-bottom: 30px">
        <div slot="header" class="clearfix">
          <span class="line-left">通知提醒</span>
        </div>
        <div class="divRow">
          <p class="textP" style="">
            <span>出入库异常行为提醒</span>
            <el-tooltip
                class="item"
                effect="dark"
                content="整箱中取单盒出入库情况进行提醒"
                placement="top"
            >
              <i style="font-size: 16px" class="el-icon-question"/>
            </el-tooltip>
          </p>
          <div class="contentRatio">
            <el-radio v-model="ruleForm.isRemind" :label="true">提醒</el-radio>
            <el-radio v-model="ruleForm.isRemind" :label="false">不提醒</el-radio>
          </div>
        </div>
        <div class="divRow">
          <p class="textP">
            <span>出入库通知单</span>
            <el-tooltip
                class="item"
                effect="dark"
                content="出入库单是否需要提前备货"
                placement="top"
            >
              <i style="font-size: 16px" class="el-icon-question"/>
            </el-tooltip>
          </p>
          <div class="contentRatio">
            <el-radio v-model="ruleForm.isPrepare" :label="true">需要</el-radio>
            <el-radio v-model="ruleForm.isPrepare" :label="false">不需要</el-radio>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div slot="header" class="clearfix" style="margin-bottom: 20px">
        <span class="line-left">入库策略</span>
      </div>
      <div class="singleDiv">
        <el-radio-group v-model="ruleForm.inWarehouse">
          <div v-for="(item,index) in inList" :key="index" class="singleRadio">
            <el-radio :label="`${item.code}`">{{ item.name }}：<span>{{ item.remarks }}</span></el-radio>
          </div>
        </el-radio-group>
      </div>
      <el-divider></el-divider>
      <div slot="header" class="clearfix" style="margin-bottom: 20px">
        <span class="line-left">出库策略</span>
      </div>
      <div class="singleDiv">
        <el-radio-group v-model="ruleForm.outWarehouse">
          <div v-for="(item,index) in outList" :key="index" class="singleRadio">
            <el-radio :label="`${item.code}`">{{ item.name }}：<span>{{ item.remarks }}</span></el-radio>
          </div>
        </el-radio-group>
      </div>
      <el-divider></el-divider>
    </div>
    <div style="margin-left: 20px">
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
import {getMySetting, saveSetting, getAllStrategy} from '@/api/crkStrategy.js'

export default {
  name: "dealerList",
  components: {},
  data() {
    return {
      inList: [],
      outList: [],
      ruleForm: {
        id: '',
        inWarehouse: '',
        outWarehouse: '',
        isRemind: false,
        isPrepare: false
      }
    };
  },
  created() {
    this.getSetting()
    this.getStrategy()
  },
  methods: {
    goBack() {

    },
    onSubmit() {
      saveSetting(this.ruleForm).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.getSetting();
        }
      })
    },
    getSetting() {
      getMySetting().then(res => {
        if (res.data) {
          this.ruleForm = res.data
        }
      })
    },
    getStrategy() {
      getAllStrategy().then(res => {
        if (res.data) {
          this.inList = []
          this.outList = []
          res.data.forEach(item => {
            if (item.type === 1) {
              this.inList.push(item)
            } else if (item.type === 2) {
              this.outList.push(item)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.singleRadio {
  margin-bottom: 20px;
}

.singleDiv {
  margin-left: 20px;
}

.pa24 {
  height: auto;
}

.remind {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 10px;
}

.divRow {
  clear: both;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.title {
  float: left;
}

.contentRatio {
  margin-left: 30px;
  float: left;
}
.textP{
  width: 20%;
  display: inline-block;
  float: left
}
</style>

